'use client';
import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { OriginString } from '@shared/types';
import Image from 'next/image';
import Link from 'next/link';
import { useShowCademyBranding } from '../../contexts/PlanContext/useShowCademyBranding';

type PoweredByProps = {
    origin: OriginString;
};

export const PoweredBy: FunctionComponent<PoweredByProps> = ({ origin }) => {
    const showCademyBranding = useShowCademyBranding();
    if (origin === 'marketplace' || showCademyBranding === false) {
        return null;
    }

    return (
        <section className={styles.poweredByContainer}>
            <Link
                target="_blank"
                rel="noopener"
                href={process.env.NEXT_PUBLIC_MARKETPLACE_URL || 'https://cademy.co.uk/'}
            >
                <div className={styles.poweredByContent}>
                    <p className={styles.poweredByContentDescription}>Powered by</p>
                    <div className={styles.poweredByContentImage}>
                        <Image
                            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/logos/logo.svg`}
                            alt="Cademy logo"
                            fill
                            priority={true}
                            sizes="max-width(640px) 100vw, 60vw"
                        />
                    </div>
                </div>
            </Link>
        </section>
    );
};
